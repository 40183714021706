import { Search } from 'lucide-react';
import { useState } from "react";
import {
  RootStyle,
  SearchIcon,
  SearchInputWrapper,
  StyledInput,
  StyleH1,
  StyleHeader,
  StyleLabelRadio,
  StyleRadios,
  StyleSpanRadio,
  StyleText
} from "./HomeSlider.style";


const HomeSlider = ({ active, setActive }) => {
  const [selectedOption, setSelectedOption] = useState("Prestataires");
  const [searchTerm, setSearchTerm] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const getPlaceholderText = () => {
    switch (selectedOption) {
      case "Prestataires":
        return "Cherchez des Prestataires de services";
      case "Marketplace":
        return "Cherchez dans la marketplace";
      default:
        return "cherchez...";
    }
  };

  const getText = () => {
    switch (selectedOption) {
      case "Prestataires":
        return "Trouvez des Prestataires de services en les contactant directement ou en partageant votre projet avec notre communauté d'indépendants.";
      case "Marketplace":
        return "Découvrez notre marketplace avec des services prêts à l'emploi, que vous pouvez également personnaliser selon les besoins de votre projet.";
      default:
        return "Search...";
    }
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      switch (selectedOption) {
        case "Prestataires":
          window.location.href = `/search/prestataires`;
          break;
        case "Marketplace":
          window.location.href = `/search/products`;
          break;
        default:
          break;
      }
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <RootStyle>
      <StyleHeader>
        <StyleH1>
          {"Identifiez le prestataire de services informatique idéal et les produits les plus adaptés à vos besoins"}
        </StyleH1>

        <StyleRadios>
          {["Prestataires", "Marketplace"].map(option => (
            <StyleLabelRadio
              key={option}
              selectedOption={selectedOption}
              option={option}
              onClick={() => setSelectedOption(option)}
            >
              <input
                type="radio"
                value={option}
                checked={selectedOption === option}
                onChange={handleOptionChange}
                style={{ display: 'none' }} // Hide the default radio button
              />
              <StyleSpanRadio
                selectedOption={selectedOption}
                option={option}
              />
              {option}
            </StyleLabelRadio>
          ))}
        </StyleRadios>

        <SearchInputWrapper>
          <SearchIcon>
            <Search size={20} />
          </SearchIcon>
          <StyledInput
            type="search"
            placeholder={getPlaceholderText()}
            value={searchTerm}
            onKeyDown={handleEnterKeyPress}
            onChange={handleSearchChange}
          />
        </SearchInputWrapper>

        <StyleText>
          {getText()}
        </StyleText>
      </StyleHeader>
    </RootStyle>
  );
};

export default HomeSlider;